<template>
  <div>
    <b-card title="" header-tag="header" class="card-custom">
      <template v-slot:header>
        <div class="card-title m-0">
          <h3 class="card-label m-0">{{ title }}</h3>
        </div>
        <div class="card-toolbar"></div>
      </template>

      <form class="form" novalidate="novalidate" id="product_basic">
        <div class="d-flex align-items-center mb-10">
          <div class="symbol symbol-40 mr-5 symbol-light-primary">
            <span class="symbol-label">
              <span class="svg-icon svg-icon-lg svg-icon-primary">
                <i class="las la-braille"></i> </span
            ></span>
          </div>
          <div class="d-flex flex-column font-weight-bold">
            <a class="text-dark text-hover-primary mb-1 font-size-lg"> General </a>
            <!-- <span class="text-muted">Sub heading</span> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-left">Image</label>
          <div class="col-lg-9 col-xl-9">
            <div class="image-input image-input-outline" id="kt_user_add_avatar">
              <div
                class="image-input-wrapper"
                :style="{
                  backgroundImage: `url(${
                    previewImage != null ? previewImage : imgPath + form.image
                  })`,
                }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  ref="file"
                  @change="selectImage"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title=""
                data-original-title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
          </div>
        </div>
 
        <b-form-group label-for="input-1">
          <label
            >Author Name <sup class="text-danger font-weight-boldest">*</sup></label
          >
          <b-form-input
            v-model="form.name"
            type="text"
            name="name"
            class="form-control"
            placeholder="EG: Shape"
          >
          </b-form-input>
        </b-form-group>
    
        <b-form-group label-for="input-1">
          <label
            >Author Slug <sup class="text-danger font-weight-boldest">*</sup></label
          >
          <b-form-input
            v-model="form.page_key"
            type="text"
            name="slug"
            class="form-control"
            placeholder="EG: Shape"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label-for="input-1">
          <label>Description <sup class="text-danger font-weight-boldest">*</sup></label>
          <Editor
            v-model="form.description"
            name="description"
            editorStyle="height: 320px"
          />
        </b-form-group>
        
        <b-col md="4">
            <b-form-group label-for="input-1">
              <label>Status <sup class="text-danger font-weight-boldest">*</sup></label>
              <b-form-select v-model="form.status">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </b-form-select>
            </b-form-group>
        </b-col>

        <b-row>
          <b-col class="text-right">
            <button ref="kt_login_signin_submit" class="btn btn-primary">
              <i class="las la-save"></i> Save
            </button>
          </b-col>
        </b-row>
      </form>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Editor from "primevue/editor";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import AuthorService from "@/core/services/api/blog/author";
import Swal from "sweetalert2";
export default {
  components: { Editor },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      currentImage: null,
      previewImage: null,
      title: "New Author",
      imgPath: process.env.VUE_APP_API_STORAGE_URL,
      form: {
        id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
        name: "",
        page_key: "",
        image: "",
        description: "",
        status: "active"
      },
    };
  },
  mounted() {
    var vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Author",
        route: "/blog-author",
      },
      {
        title: "Author : New",
      },
    ]);
    const signin_form = KTUtil.getById("product_basic");
    this.fv = formValidation(signin_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is required",
            },
          },
        },
        slug: {
          validators: {
            notEmpty: {
              message: "Slug is required",
            },
          },
        },
        group: {
          validators: {
            notEmpty: {
              message: "Attribute group is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv.on("core.form.valid", async () => {
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");

      const formData = new FormData();
      formData.append("author", JSON.stringify(vm.form));
      formData.append("image", vm.currentImage);
      if (this.id == null) {
        await this.createAuthor(formData, function (response) {
          vm.response(response, submitButton);
        });
      } else {
        await this.updateAuthor(formData, function (response) {
          vm.response(response, submitButton);
        });
      }
    });

    if (this.id != null) {
      this.getAuthorById();
    }
  },
  methods: {
    response(response, submitButton) {
      var vm = this;
      if (response) {
        let timerInterval;
        Swal.fire({
          title: response.message,
          timer: 500,
          icon: "success",
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          vm.$router.push({ name: "blog-author" });
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
          }
        });
      }
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
    },


    selectImage() {
      this.currentImage = this.$refs.file.files.item(0);
      this.previewImage = URL.createObjectURL(this.currentImage);
    },
    async getAuthorById() {
      var response = await AuthorService.getAuthorById(this.id);
      this.form = response;
      this.form.filters = this.form.filter_groups.map((e) => {
        return e.id;
      });
    },
    createAuthor(formData, callback) {
      AuthorService.createAuthor(formData)
        .then(function (response) {
          callback(response);
        })
        .catch((err) => {
          callback(false);
        });
    },
    updateAuthor(formData, callback) {
      var vm = this;
      AuthorService.updateAuthor(formData, this.id)
        .then(function (response) {
          callback(response);
        })
        .catch((err) => {
          callback(false);
        });
    },
  },
};
</script>

<style></style>
